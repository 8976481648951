import React, {Component }  from 'react';
import {leaf, run} from '../../Assets/coasterimages';
import Gallery from 'react-grid-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import axios from 'axios';
import './Bags.css'
import { images } from '../../Assets/bagimages';


//const images = [{ src: {leaf} }, { src: {leaf} }];


class Bags extends Component {

    render() {
      console.log('test2');     
        return (
            <div className="bags" onContextMenu={(e)=> e.preventDefault()}>
               
                 <div className="galleryContainer winter">
                 <Gallery images={images}/>                       
                 </div>
  
            </div>
        );
    }
}

export default Bags;
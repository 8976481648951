import { Image } from "react-grid-gallery";
export const leaf   = require('./leaf.jpeg');
export const crochetImage = require('./leaf.jpeg');
export const silhoutte =     require('./leaf.jpeg')


export const images = [
  {
    src: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2588.jpg",
    alt: "IMG_2588.jpg",
    width: 320,
    height: 174,
    thumbnail: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2588.jpg",
    tags: [
      { value: "", title: "Click here to Enlarge" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2589.jpg",
    alt: "IMG_2588.jpg",
    width: 320,
    height: 174,
    thumbnail: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2589.jpg",
    tags: [
      { value: "Click here", title: "Enlarge" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2597.jpg",
    alt: "IMG_2588.jpg",
    width: 320,
    height: 174,
    thumbnail: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2597.jpg",
    tags: [
      { value: "Click here", title: "Click here to Enlarge" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2598.jpg",
    alt: "IMG_2588.jpg",
    width: 320,
    height: 174,
    thumbnail: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2598.jpg",
    tags: [
      { value: "Click here", title: "Click here to Enlarge" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2599.jpg",
    alt: "IMG_2588.jpg",
    width: 320,
    height: 174,
    thumbnail: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2599.jpg",
    tags: [
      { value: "Click here", title: "Click here to Enlarge" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2600.jpg",
    alt: "IMG_2588.jpg",
    width: 320,
    height: 174,
    thumbnail: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2600.jpg",
    tags: [
      { value: "Click here", title: "Click here to Enlarge" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2601.jpg",
    alt: "IMG_2588.jpg",
    width: 320,
    height: 174,
    thumbnail: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2601.jpg",
    tags: [
      { value: "Click here", title: "Click here to Enlarge" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2602.jpg",
    alt: "IMG_2588.jpg",
    width: 320,
    height: 174,
    thumbnail: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2602.jpg",
    tags: [
      { value: "Click here", title: "Click here to Enlarge" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2605.jpg",
    alt: "IMG_2588.jpg",
    width: 320,
    height: 174,
    thumbnail: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2606.jpg",
    tags: [
      { value: "Click here", title: "Click here to Enlarge" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  
];

import React, {Component} from 'react';
import './Categories.css';
import {leaf, run} from '../../Assets/coasterimages';
import {Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import {instagram} from '../../Assets/coasterimages';
import StickyFooter from 'react-sticky-footer';

class Categories extends Component {

    render () {
        console.log('categories test1');
        return (
            <div className="categories-container">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 parent">
                            <img src="https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2608.jpg" alt="abstract" className="image"></img>
                                <Link to="/abstract" style={{ textDecoration: 'none' }} className="link">
                                    <div className="centered">                  
                                        <span>coasters</span>
                                    </div>
                                </Link>
                        </div>
                        <div className="col-lg-4 parent">
                            <img src="https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/bags/IMG_2658.jpg" alt="bags" className="image"></img>
                                <Link to="bags" style={{ textDecoration: 'none' }} className="link">
                                    <div className="centered">                 
                                        <span>bags and pouches</span>
                                    </div>
                                </Link>
                        </div>
                        <div className="col-lg-4 parent">
                            <img src="https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/winter/IMG_2629.jpg" alt="winter" className="image"></img>
                                <Link to="winter" style={{ textDecoration: 'none' }} className="link">
                                    <div className="centered">                               
                                        <span>winter garments</span>               
                                    </div>
                                </Link>
                        </div>
                    </div>
                </div>
                
                {/* <div className="footer">
                <StickyFooter
                    bottomThreshold={0}
                    normalStyles={{
                    backgroundColor: "#999999",
                    padding: "2rem",
                
                    }}
                    stickyStyles={{
                    backgroundColor: "rgba(255,255,255,.8)",
                    padding: "2rem"
                    }}
                >
                
                </StickyFooter>
                </div> */}

            </div>
          
        );
    }
}

export default Categories;
import { Image } from "react-grid-gallery";
export const leaf   = require('./leaf.jpeg');
export const crochetImage = require('./leaf.jpeg');
export const silhoutte =     require('./leaf.jpeg')


export const images = [
  {
    src: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/winter/IMG_2627.jpg",
    alt: "IMG_2588.jpg",
    width: 320,
    height: 174,
    thumbnail: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/winter/IMG_2627.jpg",
    tags: [
      { value: "Click ", title: "Click here to Enlarge" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/winter/IMG_2628.jpg",
    alt: "IMG_2588.jpg",
    width: 320,
    height: 174,
    thumbnail: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/winter/IMG_2628.jpg",
    tags: [
      { value: "", title: "Click here to Enlarge" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/winter/IMG_2629.jpg",
    alt: "IMG_2588.jpg",
    width: 320,
    height: 174,
    thumbnail: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/winter/IMG_2629.jpg",
    tags: [
      { value: "", title: "Click here to Enlarge" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/winter/IMG_2632.jpg",
    alt: "IMG_2588.jpg",
    width: 320,
    height: 174,
    thumbnail: "https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/winter/IMG_2632.jpg",
    tags: [
      { value: "", title: "Click here to Enlarge" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
];
